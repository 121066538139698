import React from 'react'
import styled from 'styled-components'
import darkTheme from '../theme'

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 10px;
  background: ${darkTheme.primary.color};
  color: ${darkTheme.primary.textOn};
  border-radius: 5px;
`
export const Button = ({ children, onClick }: React.HTMLAttributes<HTMLElement>) => {
  return <StyledButton onClick={onClick}>{children}</StyledButton>
}
