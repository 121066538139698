import React from 'react'
import styled from 'styled-components'

const StyledDropdown = styled.select`
  height: 25px;
  display: flex;
  background-color: #7a7a7a;
  color: #ffffff;
  justify-content: right;
  align-items: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
`

const Select = ({ children, value, onChange }: React.SelectHTMLAttributes<HTMLSelectElement>) => {
  return (
    <StyledDropdown value={value} onChange={onChange}>
      {children}
    </StyledDropdown>
  )
}

export default Select
