import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import GlobalFont from './Fonts/GlobalFont'
import reportWebVitals from './reportWebVitals'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './AuthConfig'

//MSAL instantiation
export const msalInstance = new PublicClientApplication(msalConfig)

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <GlobalFont />
    <App msalInstance={msalInstance} />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
