import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import Router from './Router'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import HoldingPage from './Pages/HoldingPage'

const queryClient = new QueryClient()

type AppProps = {
  readonly msalInstance: PublicClientApplication
}

const App = ({ msalInstance }: AppProps) => {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>
        <QueryClientProvider client={queryClient}>
          <Router />
        </QueryClientProvider>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <HoldingPage />
      </UnauthenticatedTemplate>
    </MsalProvider>
  )
}

export default App
