import React from 'react'
import styled from 'styled-components'
import darkTheme from '../theme'

const StyledCard = styled.div`
  background-color: ${darkTheme.background.variant.color};
  color: ${darkTheme.background.variant.textOn};
  padding: 10px;
  border-radius: 5px;
`
const StyledTitle = styled.div`
  font-size: 2rem;
  padding-bottom: 10px;
`
type CardProps = {
  title: string
} & React.HTMLAttributes<HTMLElement>

const Card = ({ children, title }: CardProps) => {
  return (
    <StyledCard>
      <StyledTitle>{title}</StyledTitle>
      {children}
    </StyledCard>
  )
}

export default Card
