import React from 'react'
import MenuLayout from '../Layouts/MenuLayout/MenuLayout'

const SitesPage = () => {
  return (
    <MenuLayout>
      <div>Sites</div>
    </MenuLayout>
  )
}
export default SitesPage
