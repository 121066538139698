import React from 'react'
import * as FiIcons from 'react-icons/fi'
import * as BiIcons from 'react-icons/bi'
import { RouteOptions } from '../../RouteOptions'

export const MenuOptions = [
  {
    title: 'Dashboard',
    path: RouteOptions.DashbordRoute,
    icon: <FiIcons.FiSidebar />,
  },
  // {
  //     title: 'Sites',
  //     path: RouteOptions.SitesRoute,
  //     icon: <BiIcons.BiBuilding/>,
  // },
  // {
  //     title: 'Integrations',
  //     path: RouteOptions.IntegrationsRoute,
  //     icon: <FiIcons.FiMinimize2/>,
  // },
  {
    title: 'Billing',
    path: RouteOptions.BillingRoute,
    icon: <FiIcons.FiDollarSign />,
  },
  // {
  //     title: 'Profile',
  //     path: RouteOptions.ProfileRoute,
  //     icon: <FiIcons.FiUser/>,
  // },
]
