import { IPublicClientApplication } from '@azure/msal-browser'
import axios from 'axios'
import { silentRequest } from '../../AuthConfig'
import { getEnvironmentVariables } from '../../Services/useEnvironmentVaribles'

type BillingPortalRequest = {
  redirectUri: string
}

const { apiUri } = getEnvironmentVariables()

export const CreateBillingPortalSession = async (orgId: string, instance: IPublicClientApplication) => {
  const token = await instance.acquireTokenSilent(silentRequest)
  const config = {
    headers: { Authorization: `Bearer ${token.accessToken}` },
  }
  const request: BillingPortalRequest = { redirectUri: window.location.origin }
  const res = await axios.post(`${apiUri}/Organizations/${orgId}/Billing`, request, config)
  return res.data
}
