import React from 'react'
import styled from 'styled-components'
import { Button } from '../../Components/Button'
import { useMsal } from '@azure/msal-react'
import { IPublicClientApplication } from '@azure/msal-browser'
import darkTheme from '../../theme'

const StyledHeader = styled('div')`
  background-color: ${darkTheme.background.variant.color};
  height: 80px;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 2rem;
`

const onCLickLogout = (instance: IPublicClientApplication) => {
  instance.logoutRedirect()
}

export const Header = () => {
  const { instance } = useMsal()
  return (
    <StyledHeader>
      <Button onClick={() => onCLickLogout(instance)}>Logout</Button>
    </StyledHeader>
  )
}
