import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Site } from '../../Services/Types/Site'
import MenuLayout from '../../Layouts/MenuLayout/MenuLayout'
import { getSites } from './DashboardServices'
import { useMsal } from '@azure/msal-react'
import styled from 'styled-components'
import SitesCard from './SitesCard'
import darkTheme from '../../theme'
import GuestsCard from './GuestsCard'
import UserTableCard from './UserTableCard'

const TitleText = styled.h1`
  color: ${darkTheme.background.primary.textOn};
  padding-left: 20px;
`

const Main = styled.div`
  display: grid;
  grid-template-rows: 1fr, 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 20px;
`

const orgId = '797b9aa1-9ee8-47d0-b82b-c16e2309be88'

const Dashboard = () => {
  const { instance, accounts } = useMsal()
  instance.setActiveAccount(accounts[0])
  const sites = useQuery(['getSites', orgId], () => getSites(orgId, instance))

  if (sites.status === 'loading') return <div>Loading</div>
  if (sites.status === 'error') return <div>Error</div>

  return <DashboardDisplay sitesList={sites.data} />
}

type DashboardPageProps = {
  sitesList: ReadonlyArray<Site>
}

const DashboardDisplay = ({ sitesList }: DashboardPageProps) => {
  const { instance } = useMsal()
  const [siteList] = useState<ReadonlyArray<Site>>(sitesList)

  return (
    <MenuLayout>
      <TitleText>Welcome, {instance.getActiveAccount()?.name}</TitleText>
      <Main>
        <SitesCard sitesList={siteList} />
        <GuestsCard orgId={orgId} sites={siteList} />
        <UserTableCard orgId={orgId} sites={siteList} />
      </Main>
    </MenuLayout>
  )
}

export default Dashboard
