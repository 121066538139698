import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { RouteOptions } from './RouteOptions'

// Pages
import Dashboard from './Pages/Dashboard/Dashboard'
import IntegrationsPage from './Pages/Integrations'
import SitesPage from './Pages/Sites'
import BillingPage from './Pages/Billing/Billing'
import ProfilePage from './Pages/Profile'

const Router = () => (
  <BrowserRouter basename="/">
    <Routes>
      <Route path={RouteOptions.DashbordRoute} element={<Dashboard />} />
      <Route path={RouteOptions.SitesRoute} element={<SitesPage />} />
      <Route path={RouteOptions.IntegrationsRoute} element={<IntegrationsPage />} />
      <Route path={RouteOptions.BillingRoute} element={<BillingPage />} />
      <Route path={RouteOptions.ProfileRoute} element={<ProfilePage />} />
    </Routes>
  </BrowserRouter>
)

export default Router
