import React from 'react'
import MenuLayout from '../Layouts/MenuLayout/MenuLayout'

const IntegrationsPage = () => {
  return (
    <MenuLayout>
      <div>Integrations</div>
    </MenuLayout>
  )
}
export default IntegrationsPage
