import { text } from 'node:stream/consumers'

// PRIMARY
export const PrimaryPurple = '#4826E9'
export const SecondaryPurple = '#2C11A8'
export const AlertRed = '#F34E5E'
export const SuccessGreen = '#00D159'

// BACKGROUND
export const Background = '#131313'
export const CardBackground = '#1C1C1C'
export const TableHeader = '#141414'
export const StrokeBorder = '#2B2B2B'
export const SelectedMenuItem = '#282828'

// TEXT
export const Text = '#DDDDDD'
export const HeaderText = '#282828'
export const DisabledText = '#595959'
export const LogoText = '#DDDDDD'

export type Theme = {
  name: string
  isDarkTheme: boolean
  // primary + secondary mostly for buttons and other interactable items such as Sliders
  primary: {
    color: string
    variant: string
    textOn: string
  }
  background: {
    primary: {
      color: string
      textOn: string
    }
    variant: {
      color: string
      textOn: string
    }
  }
}

export const darkTheme: Theme = {
  name: 'Primary Theme',
  isDarkTheme: false,
  primary: {
    color: PrimaryPurple,
    variant: SecondaryPurple,
    textOn: Text,
  },
  background: {
    primary: {
      color: Background,
      textOn: Text,
    },
    variant: {
      color: CardBackground,
      textOn: Text,
    },
  },
  // WARNING: ONLY USE AS A LAST RESORT OR FOR SITUATIONS WHERE THE COLOR IS THEME-AGNOSTIC, please attempt to use above themes wherever applicable
}

export default darkTheme
