import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import darkTheme from '../../theme'
import { MenuOptions } from './MenuOptions'
import { RouteOptions } from '../../RouteOptions'
import logo from '../../Assets/logo.png'

// TODO: US-36: Global Font Styling
const NavText = styled.li`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 16px 8px 16px;
  list-style: none;
  font-family: 'Inter', sans-serif;
  height: 60px;
  a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  a:hover {
    background-color: #2c11a8;
  }
`

const Span = styled.span`
  margin-left: 16px;
`

const Menu = styled.nav`
  background-color: ${darkTheme.background.variant.color};
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
`

const MenuItems = styled.ul`
  width: 100%;
  padding: 0;
`

const Logo = styled('img')`
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
`

const SideMenu = () => {
  return (
    <>
      <Menu>
        <MenuItems>
          <Link to={RouteOptions.DashbordRoute}>
            <Logo src={logo} alt="Logo" height="40" />
          </Link>
          {MenuOptions.map((item, index) => {
            return (
              <NavText key={index}>
                <Link to={item.path}>
                  {item.icon}
                  <Span>{item.title}</Span>
                </Link>
              </NavText>
            )
          })}
        </MenuItems>
      </Menu>
    </>
  )
}
export default SideMenu
