import { useMsal } from '@azure/msal-react'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import Card from '../../Components/Card'
import { Guest } from '../../Services/Types/Guest'
import { Site } from '../../Services/Types/Site'
import darkTheme from '../../theme'
import { getGuests } from './DashboardServices'
import Select from '../../Components/Select'

const StyledHeader = styled('div')`
  padding-bottom: 1rem;
`

const StyledTable = styled.table`
  width: 100%;
`

const StyledTableHead = styled.th`
  border-bottom: 1px solid ${darkTheme.primary.color};
  text-align: left;
  padding: 8px;
`

const StyledTableColumn = styled.td`
  border-bottom: 1px solid ${darkTheme.primary.color};
  text-align: left;
  padding: 8px;
`

type UserTableCardProps = {
  orgId: string
  sites: ReadonlyArray<Site>
}

const UserTableCard = ({ orgId, sites }: UserTableCardProps) => {
  const [selected, setSelected] = useState<string>(sites[0].id)

  return (
    <Card title="New Guests">
      <StyledHeader>
        <Select value={selected} onChange={e => setSelected(e.target.value)}>
          {sites.map(site => (
            <option key={site.id} value={site.id}>
              {site.siteName}
            </option>
          ))}
        </Select>
      </StyledHeader>
      <UserTableCardDataLoad orgId={orgId} siteId={selected} />
    </Card>
  )
}

type UserTableDataLoadProps = {
  orgId: string
  siteId: string
}

const UserTableCardDataLoad = ({ orgId, siteId }: UserTableDataLoadProps) => {
  const { instance, accounts } = useMsal()
  instance.setActiveAccount(accounts[0])
  const guests = useQuery(['getGuests', orgId, siteId], () => getGuests(orgId, siteId, instance))

  if (guests.status === 'loading') return <div>Loading</div>
  if (guests.status === 'error') return <div>Error</div>

  return <UserTableCardDisplay guests={guests.data} />
}

type UserTableDataDisplayProps = {
  guests: Guest[]
}

const UserTableCardDisplay = ({ guests }: UserTableDataDisplayProps) => {
  return guests.length > 0 ? (
    <StyledTable>
      <thead>
        <tr>
          <StyledTableHead>Email</StyledTableHead>
          <StyledTableHead>Phone Number</StyledTableHead>
          <StyledTableHead>Date Added</StyledTableHead>
        </tr>
      </thead>
      <tbody>
        {guests
          .sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime())
          .map((guest, index) => (
            <tr key={index}>
              <StyledTableColumn>{guest.email}</StyledTableColumn>
              <StyledTableColumn>{guest.phone}</StyledTableColumn>
              <StyledTableColumn>{new Date(guest.dateCreated.toString()).toDateString()}</StyledTableColumn>
            </tr>
          ))}
      </tbody>
    </StyledTable>
  ) : (
    <div>No data available for display.</div>
  )
}

export default UserTableCard
