export type EnvironmentVariables = {
  apiUri: string
  originUri: string
}

export const getEnvironmentVariables = (): EnvironmentVariables => {
  const checkedApiURI =
    ((window as any).API_URI as string) === '%SUBAPIURI%'
      ? 'https://api.dashboard.captivefi.com'
      : ((window as any).API_URI as string)

  const EnvironmentVars: EnvironmentVariables = {
    apiUri: checkedApiURI,
    originUri: window.location.origin,
  }
  return EnvironmentVars
}
