import React from 'react'
import styled from 'styled-components'
import ActiveIndicator from '../../Components/ActiveIndicator'
import Card from '../../Components/Card'
import { Site } from '../../Services/Types/Site'
import darkTheme from '../../theme'

const StyledTable = styled.table`
  width: 100%;
`

const StyledTableHead = styled.th`
  border-bottom: 1px solid ${darkTheme.primary.color};
  text-align: left;
  padding: 8px;
`

const StyledTableColumn = styled.td`
  border-bottom: 1px solid ${darkTheme.primary.color};
  text-align: left;
  padding: 8px;
`

type sitesCardProps = {
  sitesList: ReadonlyArray<Site>
}

const SitesCard = ({ sitesList }: sitesCardProps) => {
  return (
    <Card title="Sites">
      <StyledTable>
        <thead>
          <tr>
            <StyledTableHead>Name</StyledTableHead>
            <StyledTableHead>Active</StyledTableHead>
          </tr>
        </thead>
        <tbody>
          {sitesList.map(site => (
            <tr key={site.id}>
              <StyledTableColumn>{site.siteName}</StyledTableColumn>
              <StyledTableColumn>
                <ActiveIndicator isActive={true} />
              </StyledTableColumn>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </Card>
  )
}

export default SitesCard
