import { useMsal } from '@azure/msal-react'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'
import Card from '../../Components/Card'
import { GuestStats } from '../../Services/Types/GuestStats'
import { Site } from '../../Services/Types/Site'
import darkTheme from '../../theme'
import { getGuestsStats } from './DashboardServices'
import styled from 'styled-components'
import Select from '../../Components/Select'

const StyledHeader = styled('div')`
  padding-bottom: 1rem;
`

type GuestCardProps = {
  orgId: string
  sites: ReadonlyArray<Site>
}

const GuestsCard = ({ orgId, sites }: GuestCardProps) => {
  const [selected, setSelected] = useState<string>(sites[0].id)

  return (
    <Card title="Guest Stats">
      <StyledHeader>
        <Select value={selected} onChange={e => setSelected(e.target.value)}>
          {sites.map(site => (
            <option key={site.id} value={site.id}>
              {site.siteName}
            </option>
          ))}
        </Select>
      </StyledHeader>
      <GuestCardDataLoad orgId={orgId} siteId={selected} />
    </Card>
  )
}

type GuestCardDataLoadProps = {
  orgId: string
  siteId: string
}

const GuestCardDataLoad = ({ orgId, siteId }: GuestCardDataLoadProps) => {
  const { instance, accounts } = useMsal()
  instance.setActiveAccount(accounts[0])
  const guests = useQuery(['getGuestStats', orgId, siteId], () => getGuestsStats(orgId, siteId, instance))

  if (guests.status === 'loading') return <div>Loading</div>
  if (guests.status === 'error') return <div>Error</div>

  if (guests.status === 'success' && guests.data.length > 0) {
    guests.data.map((guest: GuestStats) => {
      guest.dateTime = new Date(guest.dateTime.toString())
    })
  }

  return <GuestsCardDisplay guests={guests.data} />
}

type GuestsCardDisplayProps = {
  guests: GuestStats[]
}
const GuestsCardDisplay = ({ guests }: GuestsCardDisplayProps) => {
  return guests.length > 0 ? (
    <div>
      <LineChart width={700} height={300} data={guests}>
        <Line type="monotone" dataKey="count" stroke={darkTheme.primary.color} />
        <CartesianGrid stroke="#ccc" />
        <XAxis dataKey="dateTime" scale="auto" tickFormatter={dateFormatter} />
        <YAxis />
        <Tooltip />
      </LineChart>
    </div>
  ) : (
    <div>No data available for display.</div>
  )
}

const dateFormatter = (date: any) => {
  return date.getMonth() + '/' + date.getDate()
}

export default GuestsCard
