import axios from 'axios'
import { silentRequest } from '../../AuthConfig'
import { getEnvironmentVariables } from '../../Services/useEnvironmentVaribles'

import { IPublicClientApplication } from '@azure/msal-browser'

export const getSites = async (orgId: string, instance: IPublicClientApplication) => {
  const token = await instance.acquireTokenSilent(silentRequest)
  const config = {
    headers: { Authorization: `Bearer ${token.accessToken}` },
  }
  const { apiUri } = getEnvironmentVariables()
  const res = await axios.get(`${apiUri}/Organizations/${orgId}/Sites`, config)
  return res.data
}

export const getGuestsStats = async (orgId: string, siteId: string, instance: IPublicClientApplication) => {
  const token = await instance.acquireTokenSilent(silentRequest)
  const config = {
    headers: { Authorization: `Bearer ${token.accessToken}` },
  }
  const { apiUri } = getEnvironmentVariables()
  const res = await axios.get(`${apiUri}/Organizations/${orgId}/Sites/${siteId}/Stats`, config)
  return res.data
}

export const getGuests = async (orgId: string, siteId: string, instance: IPublicClientApplication) => {
  const token = await instance.acquireTokenSilent(silentRequest)
  const config = {
    headers: { Authorization: `Bearer ${token.accessToken}` },
  }
  const { apiUri } = getEnvironmentVariables()
  const res = await axios.get(`${apiUri}/Organizations/${orgId}/Sites/${siteId}`, config)
  return res.data
}
