import { useMsal } from '@azure/msal-react'
import React from 'react'
import styled from 'styled-components'
import { loginRequest } from '../AuthConfig'
import logo from '../Assets/logo.png'

const Page = styled.div`
  background-color: #1e1e1e;
  min-height: 100vh;
  padding-top: 50px;
`

const Content = styled.div`
  margin: auto;
  max-width: 900px;
  display: grid;
  grid-template-rows: 50px 100px 50px;
  background-color: rgba(44, 17, 168, 0.5);
  color: white;
  border-radius: 10px;
  padding: 20px;
`

const StyledButton = styled.button`
  border-radius: 10px;
  background-color: #4826e9;
  color: white;
`

const HoldingPage = () => {
  const { instance } = useMsal()
  return (
    <Page>
      <Content>
        <img src={logo} />
        <h3>Welcome, Login to begin!</h3>
        <StyledButton onClick={() => instance.loginRedirect(loginRequest)}>Login</StyledButton>
      </Content>
    </Page>
  )
}
export default HoldingPage
