import React from 'react'
import SideMenu from './SideMenu'
import { Header } from './Header'
import styled from 'styled-components'
import darkTheme from '../../theme'
const GridContainer = styled('div')`
  display: grid;
  min-height: 100vh;
  grid-template-rows: minmax(auto, 80px) auto;
  grid-template-columns: minmax(auto, 250px) auto;
  grid-template-areas:
    'sidebar header'
    'sidebar main';
`

const GridHeader = styled('div')`
  grid-area: header;
`

const GridSideBar = styled('div')`
  grid-area: sidebar;
`

const GridMain = styled('div')`
  grid-area: main;
  grid-row-end: 3;
  background-color: ${darkTheme.background.primary.color};
  justify-items: start;
  align-items: flex-start;
  min-height: 100%;
`

const MenuLayout = ({ children }: React.HTMLAttributes<HTMLElement>) => {
  return (
    <GridContainer>
      <GridHeader>
        <Header />
      </GridHeader>
      <GridSideBar>
        <SideMenu />
      </GridSideBar>
      <GridMain>{children}</GridMain>
    </GridContainer>
  )
}

export default MenuLayout
