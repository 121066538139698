import React from 'react'
import styled from 'styled-components'

const StyledFlex = styled.div`
  display: flex;
  gap: 5px;
`

type StyledActiveIndicatorProps = {
  active: boolean
}

const StyledActiveIndicator = styled.div<StyledActiveIndicatorProps>`
  background-color: ${p => (p.active ? 'green' : 'red')};
  height: 15px;
  width: 15px;
  border-radius: 100%;
`

type activeIndicatorProps = {
  isActive?: boolean
}

const ActiveIndicator = ({ isActive = true }: activeIndicatorProps) => {
  return (
    <StyledFlex>
      <StyledActiveIndicator active={isActive} />
      {isActive ? 'Yes' : 'No'}
    </StyledFlex>
  )
}

export default ActiveIndicator
