import React, { useState } from 'react'
import MenuLayout from '../../Layouts/MenuLayout/MenuLayout'
import { useQuery } from 'react-query'
import { CreateBillingPortalSession } from './BillingServices'
import { useMsal } from '@azure/msal-react'
import styled from 'styled-components'
import darkTheme from '../../theme'

const LoadingText = styled.h1`
  color: ${darkTheme.background.primary.textOn};
`
const ErrorText = styled.h1`
  color: red;
`

type BillingPageProps = {
  portalUri: string
}

const orgId = '797b9aa1-9ee8-47d0-b82b-c16e2309be88'

const Billing = () => {
  const { instance, accounts } = useMsal()
  instance.setActiveAccount(accounts[0])
  const { data, status } = useQuery(['billingPortal', orgId], () => CreateBillingPortalSession(orgId, instance))

  if (status === 'loading')
    return (
      <MenuLayout>
        <LoadingText>Loading...</LoadingText>
      </MenuLayout>
    )
  if (status === 'error')
    return (
      <MenuLayout>
        <ErrorText>
          An Error has occurred, try refreshing the page. If you continue to see this error please contact CaptiveFi
          Support
        </ErrorText>
      </MenuLayout>
    )

  return data ? <BillingPage portalUri={data} /> : <div>Error</div>
}

const BillingPage = ({ portalUri }: BillingPageProps) => {
  window.location.href = portalUri
  return (
    <MenuLayout>
      <LoadingText>Loading...</LoadingText>
    </MenuLayout>
  )
}

export default Billing
