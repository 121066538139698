import React from 'react'
import MenuLayout from '../Layouts/MenuLayout/MenuLayout'

const ProfilePage = () => {
  return (
    <MenuLayout>
      <div>Profile</div>
    </MenuLayout>
  )
}
export default ProfilePage
